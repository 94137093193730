<template>
  <div v-if="loading">
    <PaginaCarga />
  </div>
  <div class="container-error">
    <img
      class="container-error__img"
      src="https://d2oolfm98c0n60.cloudfront.net/webs-corporativas/pharma/imagenes/Alebat-pharma-pc-6.webp"
      alt="Logo de alebat"
    />
    <p class="container-error__parrafo">
      ¡VAYA! parece que ha ocurrido un error...
    </p>
    <a class="container-error__button" href="javascript: history.go(-1)"
      >Volver atrás</a
    >
  </div>
  <CustomCursor />
</template>

<script>
import CustomCursor from "@/components/customCursor/CustomCursor.vue";
import PaginaCarga from "@/components/paginaCarga/paginaCarga.vue";
export default {
  components: {
    CustomCursor,
    PaginaCarga,
  },
};
</script>