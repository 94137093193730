<template>
  <section class="imgMov">
    <img
      class="imgMov__contenedor"
      src="https://d2oolfm98c0n60.cloudfront.net/webs-corporativas/pharma/imagenes/img-bloque-uno/contendor-flecha.svg"
      alt="contendor flecha bloque uno"
    />
    <img
      class="imgMov__flecha_mov"
      src="https://d2oolfm98c0n60.cloudfront.net/webs-corporativas/pharma/imagenes/img-bloque-uno/flecha-mov.svg"
      alt="flecha mov bloque uno"
    />
  </section>
</template>

<script>
export default {
    name: "ScrollIndicator",
};
</script>

<style>
</style>