<template>
  <section id="bannerPromocional">
    <div class="contenedor">
      <div class="contenedor__canvas">
        <canvas id="canvas" class="imagen_video"></canvas>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "BannerPromocionalPrincipal",
};
</script>