<template>
  <section id="bloqueTres">
    <BannerPromocional />

    <section class="bloqueTres">
      <p class="bloqueTres__textoBlanco">{{ datosBd.titulo.es }}</p>

      <LearndativitySVGMovil v-if="media" :textos="frases" />
      <LearndativitySVGPC v-else :textos="frases" />

      <div class="botonHablamos">
        <p class="botonHablamos__texto">Interesante. Quiero saber más</p>
        <button @click="formulario()" class="botonHablamos__boton">
          ¿Hablamos?
        </button>
      </div>

      <div class="control_pag">
        <p class="control_pag__texto" @click="animacionTexto(0)">
          03
        </p>
        <div class="control_pag__progress">
          <span class="bola"><span></span></span>
          <span class="barras" @click="animacionTexto(1)"></span>
          <span class="barras" @click="animacionTexto(2)"></span>
        </div>
        <p class="control_pag__texto" @click="animacionCambioBloque('#bloqueCuatro', 3)">04</p>
      </div>

      <scroll-indicator />
    </section>

  </section>
</template>

<script>
import ScrollIndicator from "../scrollIndicator/ScrollIndicator.vue";
import BannerPromocional from "@/components/bannerPromocional/BannerPromocionalPrincipal";
import LearndativitySVGMovil from "./componentsInternos/LearndativitySGVMovil.vue";
import LearndativitySVGPC from "./componentsInternos/LearndativitySGVPC.vue";
import { gsap, ScrollTrigger } from "gsap/all";
import { ref } from "vue";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "BloqueTresPrincipal",
  components: {
    BannerPromocional,
    ScrollIndicator,
    LearndativitySVGMovil,
    LearndativitySVGPC,
  },
  props: {
    bloque_tres: Object,
  },
  setup(props) {
    const alturaCanvas = ref(452);
    const frameImagenes = ref({ frame: 0 });
    const imagenes = ref([]);
    const frameCount = ref(75);
    const datosBd = props.bloque_tres;
    const tl = ref();
    const media = ref(window.matchMedia("(max-width: 1023px)").matches);
    const frases = [];
    Array.from(datosBd.destacadas.es).forEach((element, index) => {
      var frase = datosBd.frases.es[index].replace(element, `<strong>${element}</strong>`)
      frases[index] = frase
    });
    return {
      alturaCanvas,
      frameImagenes,
      imagenes,
      frameCount,
      datosBd,
      tl,
      media,
      frases
    };
  },
  mounted() {
    this.cargarCanvas();
  },
  watch: {
    alturaCanvas() {
      this.moverFotosVideo();
    },
  },
  methods: {
    animacionTexto(index) {
      gsap.to(window, {
        scrollTo: this.tl.scrollTrigger.labelToScroll(`animacion_${index}`),
      });
    },
    animacionCambioBloque(bloque, index) {
      gsap.to(window, {
        scrollTo: this.tl.scrollTrigger.labelToScroll(`animacion_${index}`),
        onComplete: () => {
          gsap.to(window, { duration: 1, scrollTo: bloque });
        },
      });
    },
    cargarCanvas() {
      window.addEventListener("resize", this.render);

      const currentFrame = (index) =>
        `https://d2oolfm98c0n60.cloudfront.net/webs-corporativas/pharma/imagenes/img-movimiento-video/banner_0/img-${index}.jpg`;

      for (let i = 0; i < this.frameCount; i++) {
        let img = new Image();
        img.src = currentFrame(i);
        this.imagenes.push(img);
      }

      this.imagenes[0].onload = this.render;

      this.tl = gsap.timeline({
        onUpdate: this.render,
      });

      ScrollTrigger.normalizeScroll(true);
      ScrollTrigger.create({
        animation: this.tl,
        trigger: "#bloqueTres",
        pin: true,
        markers: false,
        start: this.media ? "-80px top" : "top 80px",
        end: "+=900%",
        scrub: 1,
      });
    },
    render() {
      let divContenedor = document.getElementById("bannerPromocional");
      let canvas = document.querySelector("canvas");
      let context = canvas.getContext("2d");
      let relacionAsp = 0;
      this.media = window.matchMedia("(max-width: 1023px)").matches;

      relacionAsp = this.imagenes[0].width / this.imagenes[0].height;
      canvas.width = window.innerWidth;
      canvas.height = window.innerWidth / relacionAsp;
      this.alturaCanvas = canvas.height;

      divContenedor.style.width = window.innerWidth;
      divContenedor.style.height = window.innerWidth / relacionAsp;

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(
        this.imagenes[this.frameImagenes.frame],
        0,
        0,
        canvas.width,
        canvas.height
      );
    },
    addClass(elemento, clase) {
      Array.prototype.forEach.call(elemento, function (el) {
        el.classList.add(clase)
      });
    },
    removeClass(elemento, clase) {
      Array.prototype.forEach.call(elemento, function (el) {
        el.classList.remove(clase)
      });
    },
    moverFotosVideo() {
      var bola = document.querySelectorAll('#bloqueTres .bola');
      var learn = document.getElementsByClassName("learn");
      var data = document.getElementsByClassName("data");
      var ativity = document.getElementsByClassName("ativity");

      this.tl.to(this.frameImagenes, { frame: this.frameCount - 1, snap: "frame", ease: "none", duration: 1 });

      this.tl.to(bola, { height: 0 }).addLabel("animacion_0");
      this.tl.to(".bloqueTres", { top: -document.getElementById("bannerPromocional").clientHeight, duration: 1 });

      gsap.matchMediaRefresh;
      let mm = gsap.matchMedia();

      mm.add("(min-width: 1024px)", () => {//QUERY PARA EL PC
        this.tl.to(bola, { duration: 1, height: 35, }).addLabel("animacion_1");
        this.tl.to(".learndativity_svg__izquierda, #learning", {
          opacity: 1, duration: 1, onComplete: () => {
            gsap.set(learn,{fill:'#00edc8',animationPlayState: 'running'})
          },
          onReverseComplete: () => {
            gsap.set(learn,{clearProps: 'all'})
          }
        }, '-=1');

        this.tl.to(bola, { duration: 1, height: 71, }).addLabel("animacion_2");
        this.tl.to(".learndativity_svg__centro, #data", {
          opacity: 1, duration: 1,
          onComplete: () => {
            gsap.set(data,{fill:'#00edc8',animationPlayState: 'running'})
          },
          onReverseComplete: () => {
            gsap.set(data,{clearProps: 'all'})
            gsap.set(learn,{fill:'#00edc8',animationPlayState: 'running'})
          }
        }, '-=1');

        this.tl.to(bola, { duration: 1, height: 100, }).addLabel("animacion_3");
        this.tl.to(".learndativity_svg__derecha, #creativity", {
          opacity: 1, duration: 1,
          onComplete: () => {
            gsap.set(ativity,{fill:'#00edc8',animationPlayState: 'running'})
          },
          onReverseComplete: () => {
            gsap.set(ativity,{clearProps: 'all'})
            gsap.set(data,{fill:'#00edc8',animationPlayState: 'running'})
          }
        }, '-=1').set({}, {}, "+=1");

      })//FIN QUERY PARA EL PC
      mm.add("(max-width: 1023px)", () => {//QUERY PARA EL MOVIL

        this.tl.to(".learndativity_svg__izquierda", { opacity: 1, duration: 1 });
        this.tl.to("#learndativity_svg .contenedor__interno", {
          top: "0%", duration: 1, onComplete: () => {
            gsap.set(learn,{fill:'#00edc8',animationPlayState: 'running'})
          },
          onReverseComplete: () => {
            gsap.set(learn,{clearProps: 'all'})
          },
        }, "-=1");

        this.tl.to(".learndativity_svg__izquierda", { opacity: 0, duration: 1 });
        this.tl.to("#learning", { opacity: 0, duration: 1 }, "-=1");

        this.tl.to(".learndativity_svg__centro", { opacity: 1, duration: 1 }, "-=.5");
        this.tl.to("#data,#creativity", {
          top: "-60%", duration: 1,
          onComplete: () => {
            gsap.set(data,{fill:'#00edc8',animationPlayState: 'running'})
          },
          onReverseComplete: () => {
            gsap.set(data,{clearProps: 'all'})
            gsap.set(learn,{fill:'#00edc8',animationPlayState: 'running'})
          },
        }, "-=1.5");

        this.tl.to(".learndativity_svg__centro", { opacity: 0, duration: 1 });
        this.tl.to("#data", { opacity: 0, duration: 1 }, "-=1");

        this.tl.to(".learndativity_svg__derecha", { opacity: 1, duration: 1 }, "-=.5");
        this.tl.to("#creativity", {
          top: "-120%", duration: 1,
          onComplete: () => {
            gsap.set(ativity,{fill:'#00edc8',animationPlayState: 'running'})
          },
          onReverseComplete: () => {
            gsap.set(ativity,{clearProps: 'all'})
            gsap.set(data,{fill:'#00edc8',animationPlayState: 'running'})
          },
        }, "-=1.5").set({}, {}, "+=1");
      }) // FIN QUERY PARA EL MOVIL
    }, //fin del metodo
    formulario() {
      document.getElementById("contacta").scrollIntoView();
    },
    moverScroll() {
      document.getElementById("bloqueCuatro").scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>