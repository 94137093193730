<template>
  <div class="cursor"></div>
  <div class="cursor-follower"></div>
</template>

<script>
import { gsap } from "gsap";
export default {
  name: "CustomCursor",
  mounted() {
    var cursor = document.querySelector(".cursor"),
      follower = document.querySelector(".cursor-follower"),
      list = [],
      li_element = Array.from(document.getElementsByTagName("li")),
      a_element = Array.from(document.getElementsByTagName("a")),
      button_element = Array.from(document.getElementsByTagName("button")),
      clicable_element = Array.from(document.querySelectorAll(".clicable"));
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      cursor.style.display = "none";
      follower.style.display = "none";
    } else {
      list.push(li_element, a_element, button_element, clicable_element);

      var posX = 0,
        posY = 0;

      var mouseX = 0,
        mouseY = 0;

      gsap.to({}, 0.016, {
        repeat: -1,
        onRepeat: function () {
          posX += (mouseX - posX) / 3;
          posY += (mouseY - posY) / 3;

          gsap.set(follower, {
            css: {
              left: posX - 12,
              top: posY - 12,
            },
          });

          gsap.set(cursor, {
            css: {
              left: mouseX,
              top: mouseY,
            },
          });
        },
      });

      document.addEventListener("mousemove", function (e) {
        mouseX = e.clientX;
        mouseY = e.clientY;
      });

      Array.from(list).forEach((array) => {
        array.forEach((element) => {
          element.addEventListener("mouseenter", function () {
            cursor.classList.add("active");
            follower.classList.add("active");
            if(element.id == 'buttonCompartirLearndativity'){
              cursor.classList.remove("active");
              follower.classList.remove("active");
            }
          });
          element.addEventListener("mouseleave", function () {
            cursor.classList.remove("active");
            follower.classList.remove("active");
          });
        });
      });
    }
  },
};
</script>