<template>
  <nav id="menu" class="menu">
    <a href="/">
      <picture>
        <img
          class="logo"
          src="https://d2oolfm98c0n60.cloudfront.net/webs-corporativas/pharma/imagenes/Alebat-pharma-pc-6.webp"
          alt=""
        />
      </picture>
    </a>

    <div
      id="menu__hamburguesa"
      class="menu__hamburguesa"
      @click="desplegable()"
    >
      <span></span>
      <span></span>
      <span></span>
    </div>

    <div id="menu__contenido" class="menu__contenido" @click="desplegable()">
      <ul>
        <!-- <li>
          <router-link to="/blog">
            <p class="texto">Blog</p>
          </router-link>
        </li> -->
        <li>
          <a href="/servicios" target="_blank">
            <p class="texto">Servicios</p>
          </a>
        </li>
        <li class="cursor_pointer">
          <a href="/#contacta" id="cambioTexto" class="cambioTexto">
            <p>No me cuentes más, quiero que hablemos.</p>
          </a>
          <a href="/#contacta" id="cambioTextoBoton" class="cambioTextoBoton">
            <p>No me cuentes más, quiero que hablemos.</p>
          </a>
        </li>
      </ul>
    </div>
  </nav>
  <CustomCursor />
</template>

<script>
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import CustomCursor from "../../components/customCursor/CustomCursor.vue";
gsap.registerPlugin(ScrollToPlugin);
export default {
  name: "menuPrincipal",
  components: {
    CustomCursor,
  },
  mounted() {
    this.cambioTextoMenu();
  },
  methods: {
    desplegable() {
      if (
        window.matchMedia("(max-width: 767px)").matches ||
        window.matchMedia("(max-width: 900px)").matches
      ) {
        document
          .getElementById("menu__hamburguesa")
          .classList.toggle("menu__hamburguesa--open");
        document
          .getElementById("menu__contenido")
          .classList.toggle("menu__contenido--open");
        /*Poner y quitar scroll de la pantalla*/
        document
          .getElementById("menu__contenido")
          .classList.toggle("menu__hamburguesa--open")
          ? (document.scrollingElement.style.overflow = "hidden")
          : (document.scrollingElement.style.overflow = "auto");
      }
    },
    cambioTextoMenu() {
      // var scrollPos = 0;
      window.onscroll = function () {
        /*Poner y quitar menu de la pantalla*/
        // if ((document.body.getBoundingClientRect()).top > scrollPos) {
        //   document.getElementById('menu').classList.remove('menu--desaparece');
        // } else {
        //   document.getElementById('menu').classList.add('menu--desaparece');
        // }
        // scrollPos = (document.body.getBoundingClientRect()).top;
        /*Fin poner y quitar menu de la pantalla*/

        var b1 = document.getElementById("bloqueUno");
        if (b1 != null) {
          var b2 = document.getElementById("bloqueDos");
          var b3 = document.getElementById("bloqueTres");
          var b4 = document.getElementById("bloqueCuatro");
          var b5 = document.getElementById("contacta");

          switch (true) {
            case b1.getBoundingClientRect().top <= 0 &&
              b2.getBoundingClientRect().top > 0:
              document.getElementById("cambioTexto").textContent =
                "No me cuentes más, quiero que hablemos.";
              document.getElementById("cambioTextoBoton").textContent =
                "No me cuentes más, quiero que hablemos.";

              /*HOVER*/
              document
                .getElementById("cambioTextoBoton")
                .classList.add("cambioTextoBoton--b1");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b2");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b3");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b4");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b5");
              break;

            case b2.getBoundingClientRect().top <= 0 &&
              b3.getBoundingClientRect().top > 0:
              document.getElementById("cambioTexto").innerHTML = "<p>Hablemos</p>";
              document.getElementById("cambioTextoBoton").textContent =
                "¡Pienso como vosotros! Hablemos";

              /*HOVER*/
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b1");
              document
                .getElementById("cambioTextoBoton")
                .classList.add("cambioTextoBoton--b2");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b3");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b4");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b5");
              break;

            case b3.getBoundingClientRect().top <= 0 &&
              b4.getBoundingClientRect().top > 0:
              document.getElementById("cambioTexto").innerHTML = "<p>¿Hablamos?</p>";
              document.getElementById("cambioTextoBoton").textContent =
                "Interesante. Quiero saber más. ¿Hablamos?";

              /*HOVER*/
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b1");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b2");
              document
                .getElementById("cambioTextoBoton")
                .classList.add("cambioTextoBoton--b3");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b4");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b5");
              break;

            case b4.getBoundingClientRect().top <= 0 &&
              b5.getBoundingClientRect().top > 0:
              document.getElementById("cambioTexto").innerHTML =
                "<p>¡Quiero que me ayudéis!</p>";
              document.getElementById("cambioTextoBoton").textContent =
                "Necesito estos servicios ¡Quiero que me ayudéis!";

              /*HOVER*/
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b1");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b2");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b3");
              document
                .getElementById("cambioTextoBoton")
                .classList.add("cambioTextoBoton--b4");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b5");
              break;

            case b5.getBoundingClientRect().top <= 0:
              document.getElementById("cambioTexto").innerHTML =
                "<p>¡Hablemos!</p>";
              document.getElementById("cambioTextoBoton").innerHTML =
                "<p>¡Hablemos!</p>";

              /*HOVER*/
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b1");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b2");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b3");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b4");
              document
                .getElementById("cambioTextoBoton")
                .classList.add("cambioTextoBoton--b5");
              break;

            default:
              document.getElementById("cambioTexto").textContent =
                "No me cuentes más, quiero que hablemos.";
              document.getElementById("cambioTextoBoton").textContent =
                "No me cuentes más, quiero que hablemos.";

              /*HOVER*/
              document
                .getElementById("cambioTextoBoton")
                .classList.add("cambioTextoBoton--b1");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b2");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b3");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b4");
              document
                .getElementById("cambioTextoBoton")
                .classList.remove("cambioTextoBoton--b5");
              break;
          }
        }
      };
    },
    // irForm() {
    //   if (window.location.href.includes("servicios")) {
    //     window.location.href = "/#contacta";
    //   } else {
    //     gsap.to(window, { duration: 1.5, scrollTo: "#contacta" });
    //   }
    // },
  },
};
</script>