<template>
  <section id="contacta">
    <section class="contenedor-form">
      <div class="contenedor-form__tituloPrincipal">
        <p>Cuéntanos tu experiencia, estamos aquí para ayudarte</p>
      </div>
      <div class="contenedor-form__form">
        <p class="titulo-formulario">
          Para facilitarte el trabajo, hemos dejado una lista de temas sobre los
          que nos suelen preguntar <br />(dale a la flechita junto a "Lo que
          necesitamos es...", por si es tu caso). O si lo prefieres puedes
          personalizar el mensaje.
        </p>
        <form
          id="formPharma"
          action="https://forms.zohopublic.com/alebateducation/form/formwebpharma/formperma/7SmFzKlo6qNJkBdsLR064xMYyGh7SgBKUROpTTS_cMQ/htmlRecords/submit"
          name="form"
          method="POST"
          accept-charset="UTF-8"
          enctype="multipart/form-data"
        >
          <div class="user-box clicable">
            <label form="username">Nombre: </label>
            <input
              id="username"
              type="text"
              name="SingleLine"
              checktype="c1"
              title="Escribir nombre y apellidos."
              pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
              required
            />
          </div>

          <div class="user-box clicable">
            <label>Correo Electrónico: </label>
            <input
              type="mail"
              name="SingleLine1"
              checktype="c1"
              title="Ejemplo: mail@mail.com"
              pattern="^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
              required
            />
          </div>

          <div class="user-box clicable">
            <label>Teléfono: </label>
            <input
              type="number"
              name="SingleLine2"
              checktype="c1"
              title="Introducir solo numeros"
              required
            />
          </div>
          <select id="asunto" class="clicable">
            <option value="">
              Lo que necesitamos es (Elige una opción)...
            </option>
            <option
              value="Quiero saber más sobre cómo aplicar el Learndativity en mi Marca,me parece interesante."
            >
              Quiero saber más sobre cómo aplicar el Learndativity en mi Marca,
              me parece interesante.
            </option>
            <option
              value="Me interesa saber lo que hacéis como Agencia Creativa. Queremos hacer cosas nuevas..."
            >
              Me interesa saber lo que hacéis como Agencia Creativa. Queremos
              hacer cosas nuevas...
            </option>
            <option
              value="¿Me podéis ayudar a conseguir más datos de usuarios en mis proyectos?"
            >
              ¿Me podéis ayudar a conseguir más datos de usuarios en mis
              proyectos?
            </option>
            <option
              value="  Quiero hacer un Lanzamiento de producto en el Canal Farmacia, con su evento y su comunicación y su web y todo…"
            >
              Quiero hacer un Lanzamiento de producto en el Canal Farmacia, con
              su evento y su comunicación y su web y todo…
            </option>
            <option
              value="Quiero también capacitar a mi equipo, y no se cómo o por dóndeempezar…¿Me ayudáis?"
            >
              Quiero también capacitar a mi equipo, y no se cómo o por dónde
              empezar…¿Me ayudáis?
            </option>
            <option
              value="Hacemos ya cosas para el Canal Farmacia, pero nos cuesta llegar al paciente. ¿Podéis echarnos una mano?"
            >
              Hacemos ya cosas para el Canal Farmacia, pero nos cuesta llegar al
              paciente. ¿Podéis echarnos una mano?
            </option>
          </select>

          <textarea
            id="areaTexto"
            value=""
            name="SingleLine3"
            pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
            required
            placeholder="ó escribe lo que necesitas"
          >
          </textarea>
        </form>
      </div>
    </section>

    <div class="contenedor-form__boton">
      <button type="submit" v-on:click="verificarSeleccion" form="formPharma">
        ¡Hablemos!
      </button>
    </div>

    <section>
      <div @click="irForm()" class="control_pag">
        <div class="control_pag__progress"></div>
        <button class="control_pag__texto">TOP</button>
      </div>
    </section>
  </section>
</template>

<script>
import { gsap } from "gsap";
export default {
  name: "bloqueCincoPrincipal",
  mounted() {
    //this.verificarSeleccion();
    this.notificacionesForm();
    this.actualizarTextArea();
  },

  methods: {
    actualizarTextArea() {
      let opciones = document.querySelector("#asunto");
      let textoArea = document.getElementById(".areaTexto");

      opciones.addEventListener("change", () => {
        let opcion = event.target.value;

        document.getElementById("areaTexto").value = "" + opcion;
      });
    },
    verificarSeleccion() {
      var e = document.getElementById("asunto");
      const date = new Date();
      date.setTime(date.getTime() + 36000 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + date.toUTCString();
      document.cookie = `isFormComplete=1; ${expires}; path=/;`;
    },

    irForm() {
      gsap.to(window, { duration: 1.5, scrollTo: "#bloqueUno" });
    },

    notificacionesForm() {
      /*     var input = document.getElementById("formPharma");
        var form = document.getElementById("form");
        var elem = document.createElement("div");
        elem.id = "notify";
        elem.style.display = "none";

        form.appendChild(elem);

        input.addEventListener("invalid", function (event) {
          event.preventDefault();
          if (!event.target.validity.valid) {
            input.className = "invalid animated shake";
            elem.textContent =
              "Username should only contain lowercase letters e.g. john";
            elem.className = "error";
            elem.style.display = "block";
          }
        });

        input.addEventListener("input", function (event) {
          if ("block" === elem.style.display) {
            input.className = "";
            elem.style.display = "none";
          }
        }); */
    },
  },
};
</script>