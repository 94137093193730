<template>
    <div id="learndativity_svg">
        <svg id="svg_learndativity" class="learndativity_svg" viewBox="0 0 1017.91 308.69">
            <text class="learndativity_svg__titulo" transform="translate(.33 89.35)">
                <tspan class="learn" x="4" y="0">LEARN</tspan>
                <tspan class="data" x="405" y="0">D</tspan>
                <tspan class="data ativity" x="490" y="0">AT</tspan>
                <tspan class="ativity" x="650" y="0">IVITY</tspan>
            </text>
            <g class="learndativity_svg__izquierda">
                <polyline class="learndativity_svg__barra"
                    points="31.61 124.11 42.28 134.78 359.4 134.78 369.87 124.31" />
                <g>
                    <polyline class="learndativity_svg__barra"
                        points="485.51 278.8 485.51 181.54 470.46 169.79 210.02 169.79 198.72 161.64 198.72 134.78" />
                    <polygon class="learndativity_svg__flecha"
                        points="505.34 308.69 485.4 275.21 465.46 308.69 505.34 308.69" />
                </g>
            </g>
            <g class="learndativity_svg__centro">
                <polyline class="learndativity_svg__barra"
                    points="434.76 108.11 445.43 118.78 598.76 118.78 609.23 108.31" />
                <g>
                    <polyline class="learndativity_svg__barra"
                        points="521.64 118.78 521.64 166.86 485.02 203.48 485.02 278.8" />
                    <polygon class="learndativity_svg__flecha"
                        points="465.19 308.69 485.13 275.21 505.08 308.69 465.19 308.69" />
                </g>
            </g>
            <g class="learndativity_svg__derecha">
                <polyline class="learndativity_svg__barra" points="545.33 136 556 146.67 947.8 146.67 958.26 136.2" />
                <g>
                    <polyline class="learndativity_svg__barra"
                        points="751.8 146.67 751.8 172.65 740.09 184.36 502.06 184.36 485.51 200.9 485.51 278.8" />
                    <polygon class="learndativity_svg__flecha"
                        points="465.68 308.69 485.62 275.21 505.57 308.69 465.68 308.69" />
                </g>
            </g>
        </svg>
        <div class="contenedor">
            <div id="learning" class="contenedor__interno">
                <p class="contenedor__titulo">LEARNING</p>
                <p class="contenedor__texto" v-html="textos[0]"></p>
            </div>
            <div id="data" class="contenedor__interno">
                <p class="contenedor__titulo">DATA</p>
                <p class="contenedor__texto" v-html="textos[1]"></p>
            </div>
            <div id="creativity" class="contenedor__interno">
                <p class="contenedor__titulo">CREATIVITY</p>
                <p class="contenedor__texto" v-html="textos[2]"></p>
            </div>
        </div>

    </div>
</template>

<script >
export default {
    name: "Learndativity SVG Movil",
    props: {
        textos: Object,
    },
    setup(props) {
        const textos = props.textos;
        return { textos };
    },
};
</script>