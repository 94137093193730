<template>
  <div id="mensajeCookies">
    <div class="cookies-background-block">
      <div id="cookies-panel" class="panel">
        <div class="cookie-banner-small">
          <div class="posicionTextoCookies">
            <p class="titulo-cookie fontMedium textoBlanco">
              Tu privacidad es importante para nosotros
            </p>
            <p class="texto-cookie fontBook textoBlanco">
              En esta web utilizamos tecnologías como cookies propias y de
              terceros, y procesamos datos personales, como las direcciones IP y
              los identificadores de cookies, para personalizar los anuncios y
              el contenido según sus intereses, medir el rendimiento de los
              anuncios y el contenido y obtener información sobre las audencias
              que vieron los anuncios y dicho contenido. Puede consultar nuestra
              <a class="fontSemibold textoBlanco mostrarCookies" href="/cookies"
                >Política de Cookies aquí.</a >
            </p>
          </div>
          <div class="botonCookie">
            <button id="allow-cookies" class="button expand fontSemibold">
              ACEPTAR TODAS
            </button>
            <button id="denied-cookies" class="button expand fontSemibold">
              RECHAZAR TODAS
            </button>
            <button id="conf-cookies" class="button expand fontSemibold">
              CONFIGURAR
            </button>
          </div>
        </div>
        <div class="cookie-banner-big">
          <div class="opcionesCookies">
            <p id="back-cookie" class="textoBlanco fontLight">Volver</p>
            <p class="titulo-cookie fontMedium textoBlanco">
              Tu privacidad es importante para nosotros
            </p>
            <p class="texto-cookie fontBook textoBlanco">
              Puedes configurar tus preferencias y elegir como quieres que tus
              datos sean utilizados para los siguientes propósitos.
            </p>
            <form class="cookies-conf">
              <div class="divOpciones">
                <ul>
                  <li class="round">
                    <input
                      disabled
                      checked
                      type="checkbox"
                      name="cookies-obligatorias"
                      value="obligatorias"
                      id="obligatorias"
                    />
                    <label for="obligatorias"></label>
                    <span class="fontBook textoBlanco">Obligatorias</span>
                    <p class="cookies-options fontBook textoBlanco">
                      Cookies estrictamente necesarias para prestar un servicio
                      o contenido solicitado por el usuario. éstas se encargan
                      de aspectos técniso como identificar registros y gestionar
                      preferencias de herramientas en el uso, interacción y
                      localización del acceso a los contenidos.
                    </p>
                  </li>
                  <li class="round">
                    <input
                      type="checkbox"
                      name="cookies-estadistica"
                      value="estadistica"
                      id="estadisticas"
                    />
                    <label for="estadisticas"></label>
                    <span class="fontBook textoBlanco">Estadisticas</span>
                    <p class="cookies-options fontBook textoBlanco">
                      Estas cookies recogen información relativa al uso de la
                      página como la permanencia en página y los contenidos
                      visitados por los lectores para medir su rendimiento y
                      mejorar la navegación.
                    </p>
                  </li>
                </ul>
              </div>
            </form>
          </div>
          <div class="botonCookie">
            <button id="denied-all" class="button expand fontSemibold">
              RECHAZAR TODO
            </button>
            <button id="allow-all" class="button expand fontSemibold">
              HABILITAR TODO
            </button>
            <button id="save-cookies" class="button expand fontSemibold">
              GUARDAR Y SALIR
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const $ = require("jquery");
import { mapState, mapMutations } from "vuex";

export default {
  el: "#menuMovil",
  name: "menuMovil", 
  mounted() {
    var posicion = $(window).scrollTop();
    //Para prevenir que el menu desaparezca en iOS al hacer scroll hacia arriba en el bloque Uno.
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll <= 200) {
        $("#menuMovil").removeClass("esconder-menu");
      } else if (scroll > posicion && scroll > 200) {
        $("#menuMovil").addClass("esconder-menu");
      } else {
        $("#menuMovil").removeClass("esconder-menu");
      }
      posicion = scroll;
    });
    //Hacer invisible el modal.
    window.onclick = function (event) {
      var modalLegal = document.getElementById("modalLegal");
      var modalPrivacidad = document.getElementById("modalPrivacidad");
      var modalCookie = document.getElementById("modalCookies");
      if (event.target == modalPrivacidad) {
        modalPrivacidad.style.display = "none";
        $(modalCookie).is("visible") ? "" : $("html").css({ overflow: "auto" });
      } else if (event.target == modalCookie) {
        modalCookie.style.display = "none";
        $(modalPrivacidad).is("visible")
          ? ""
          : $("html").css({ overflow: "auto" });
      } else if (event.target == modalLegal) {
        modalLegal.style.display = "none";
        $("html").css({ overflow: "auto" });
      }
    };

    $("#modalLegal").on("click", ".close", function () {
      $("#modal" + $(this).closest(".modal-content").attr("id")).css({
        display: "none",
      });
      if (
        !$("#modalCookies, #modalPrivacidad").is(":visible") &&
        !$("#menuMovil").hasClass("menuAbierto")
      ) {
        $("html").css({ overflow: "auto" });
      }

      if (
        !$(".contenidoMenu").hasClass("abrirMenu") &&
        $(window).scrollTop() >= 200
      ) {
        $("#menuMovil").addClass("esconder-menu");
      }
    });

    $("#modalPrivacidad").on("click", ".close", function () {
      $("#modal" + $(this).closest(".modal-content").attr("id")).css({
        display: "none",
      });
      if (
        !$("#modalCookies, #modalPrivacidad").is(":visible") &&
        !$("#menuMovil").hasClass("menuAbierto")
      ) {
        $("html").css({ overflow: "auto" });
      }

      if (
        !$(".contenidoMenu").hasClass("abrirMenu") &&
        $(window).scrollTop() >= 200
      ) {
        $("#menuMovil").addClass("esconder-menu");
      }
    });
    $("#modalCookies").on("click", ".close", function () {
      $("#modal" + $(this).closest(".modal-content").attr("id")).css({
        display: "none",
      });
      if (
        !$("#modalCookies, #modalPrivacidad").is(":visible") &&
        !$("#menuMovil").hasClass("menuAbierto")
      ) {
        $("html").css({ overflow: "auto" });
      }

      if (
        !$(".contenidoMenu").hasClass("abrirMenu") &&
        $(window).scrollTop() >= 200
      ) {
        $("#menuMovil").addClass("esconder-menu");
      }
    });
    $(".mostrarPrivacidad").click(function () {
      if ($(this).hasClass("dentroDeCookies")) {
        $("#modalCookies").css({ "z-index": "9998" });
        $("#modalPrivacidad").css({ "z-index": "9999" });
      }
      $("#modalPrivacidad").css({ display: "flex" });
      $("html").css({ overflow: "hidden" });
    });

    $("body").on("click", ".mostrarPrivacidad", function () {
      if ($(this).hasClass("dentroDeCookies")) {
        $("#modalCookies").css({ "z-index": "9998" });
        $("#modalPrivacidad").css({ "z-index": "9999" });
      }
      $("#modalPrivacidad").css({ display: "flex" });
      $("html").css({ overflow: "hidden" });
    });

    $(".mostrarCookies").click(function () {
      if ($(this).hasClass("dentroDePrivacidad")) {
        $("#modalPrivacidad").css({ "z-index": "9998" });
        $("#modalCookies").css({ "z-index": "9999" });
      }
      $("#modalCookies").css({ display: "flex" });
      $("html").css({ overflow: "hidden" });
      $("#menuMovil").removeClass("esconder-menu");
    });

    $(".panelCookies").click(function () {
      $("#cookies-panel, .cookies-background-block").fadeIn(100);
    });

    $(".mostrarLegal").click(function () {
      $("#modalLegal").css({ display: "flex" });
      $("html").css({ overflow: "hidden" });
    });
  },
  methods: {
    ...mapMutations("opcionMenu", ["cambiarOpcion"]),
    cerrarMenu() {
      if ($(".menu-lineas").hasClass("cerrarMenu")) {
        $("html").css({ overflow: "auto" });
        $("html").removeClass("quitarScroll");
        $(".menu-lineas").removeClass("cerrarMenu");
        $("#menuMovil").removeClass("menuAbierto");
        $(".contenidoMenu").removeClass("abrirMenu");
        $(".imagenLogoMovilBlanco").removeClass("logoInvisible");
        $(".imagenLogoMovilNegro").removeClass("logoVisible");
      } else {
        $("html").css({ overflow: "hidden" });
        $("html").addClass("quitarScroll");
        $(".menu-lineas").addClass("cerrarMenu");
        $("#menuMovil").addClass("menuAbierto");
        $(".contenidoMenu").addClass("abrirMenu");
        $(".imagenLogoMovilBlanco").addClass("logoInvisible");
        $(".imagenLogoMovilNegro").addClass("logoVisible");
      }
    },

    cerrar() {
      if ($("#menuMovil").addClass("menuAbierto")) {
        $("html").css({ overflow: "auto" });
        $("html").removeClass("quitarScroll");
        $(".menu-lineas").removeClass("cerrarMenu");
        $("#menuMovil").removeClass("menuAbierto");
        $(".contenidoMenu").removeClass("abrirMenu");
        $(".imagenLogoMovilBlanco").removeClass("logoInvisible");
        $(".imagenLogoMovilNegro").removeClass("logoVisible");
      }
    },
  },
};
</script>