<template>
  <section id="bloqueCuatro" class="bloque">
    <div class="bloqueUno">
      <div class="bloqueSuperior">
        <p class="titulo-bloque-uno">{{ datos.titulo.es }}</p>
        <img v-bind:src="datos.principal.imagen" loading="lazy" />
        <div class="titulo-bloque-uno__conocelos">
          <p>Conócelos con estos tres videos</p>
          <img
            class="titulo-bloque-uno__conocelos-flecha"
            src="https://d2oolfm98c0n60.cloudfront.net/webs-corporativas/pharma/imagenes/img-bloque-cuatro/flecha-verde.svg"
            alt=""
          />
        </div>
      </div>
    </div>
    <!--Arriba-->

    <div class="bloqueDos">
      <div class="bloqueMedio clicable">
        <div class="bloqueCentral" v-on:click="ampliar">
          <div class="bloque__video">
            <video class="video_player" controls muted>
              <source v-bind:src="datos.learning.video" type="video/mp4" />
            </video>
            <div v-on:click="cerrarVideo" class="cerrar">
              <svg
                class="cerrar__icono"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </div>
            <div class="go-to-servicios">
              <a href="/servicios">Descubre más aquí</a>
            </div>
          </div>
          <img v-bind:src="datos.learning.imagen" loading="lazy" />
        </div>
      </div>
      <div class="logo">
        <img
          src="https://d2oolfm98c0n60.cloudfront.net/webs-corporativas/pharma/imagenes/Alebat-pharma-pc-6.webp"
          alt="Logo de alebat pharma"
        />
      </div>
      <div class="bloqueAbajo">
        <div class="abajoIzquierda clicable" v-on:click="ampliar">
          <div class="bloque__video">
            <video class="video_player" controls muted>
              <source v-bind:src="datos.data.video" type="video/mp4" />
            </video>
            <div v-on:click="cerrarVideo" class="cerrar">
              <svg
                class="cerrar__icono"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </div>
            <div class="go-to-servicios">
              <a href="/servicios">Descubre más aquí</a>
            </div>
          </div>
          <img v-bind:src="datos.data.imagen" loading="lazy" />
        </div>
        <div class="abajoDerecha clicable" v-on:click="ampliar">
          <div class="bloque__video">
            <video class="video_player" controls muted>
              <source v-bind:src="datos.creativity.video" type="video/mp4" />
            </video>
            <div v-on:click="cerrarVideo" class="cerrar">
              <svg
                class="cerrar__icono"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </div>
            <div class="go-to-servicios">
              <a href="/servicios">Descubre más aquí</a>
            </div>
          </div>
          <img v-bind:src="datos.creativity.imagen" loading="lazy" />
        </div>
      </div>
    </div>
    <!--Abajo-->
  </section>
</template>


<script>
export default {
  name: "bloqueCuatroPrincipal",
  props: {
    bloque_cuatro: Object,
  },

  data: {
    isMovile: window.innerHeight > window.innerWidth,
  },

  setup(props) {
    const datos = props.bloque_cuatro;
    return { datos };
  },

  methods: {
    ampliar(event) {
      // DECLARACION DE VARIABLES
      let clickedElement = event.target.previousElementSibling;
      let parentClickedElement = clickedElement.parentElement;
      let video = clickedElement.firstElementChild;

      // FUNCIONALIDAD MOVIL
      if (!event.target.classList.contains("cerrar") && this.isMovile) {
        //parentClickedElement.classList.add("hacerGrande");
        clickedElement.style.display = "block";
        setTimeout(() => {
          clickedElement.classList.add("activarBloque");
        }, 600);
        video.play();
        video.muted = false;
      } else if (!event.target.classList.contains("cerrar") && !this.isMovile) {
        // FUNCIONALIDAD PC
        //parentClickedElement.classList.add("hacerGrande");
        clickedElement.style.display = "block";
        setTimeout(() => {
          clickedElement.classList.add("activarBloque");
        }, 600);
        video.play();
        video.muted = false;
      }
    },

    cerrarVideo(event) {
      let bloqueVideo = event.target.parentElement;
      let bloquePrincipal = bloqueVideo.parentElement;
      let video = event.target.previousElementSibling;

      if (event.target.classList.contains("cerrar")) {
        bloqueVideo.classList.remove("activarBloque");
        bloquePrincipal.classList.remove("hacerGrande");
        bloqueVideo.style.display = "none";
        video.pause();
        video.muted = true;
      }
    },
  },
};
</script>