export default {
  //Conexion bbdd test
  test:{
    "graphqlEndpoint": "https://4jlzcmlgxbgyxc7lhxuqcawbdm.appsync-api.eu-west-1.amazonaws.com/graphql",
    "region": "eu-west-1",
    "authType": "API_KEY",
    "apiKey": "da2-aihfrxpc2fbr3gdhi57l5muqcu"
  },
  //Conexion bbdd produccion
  produccion:{
    "graphqlEndpoint": "https://ztwnc5gnurbkxkxzdvqmwg7soa.appsync-api.eu-west-1.amazonaws.com/graphql",
    "region": "eu-west-1",
    "authType": "API_KEY",
    "apiKey": "da2-73wibxpugrdlzivff26yioesr4"
  }
}

  
