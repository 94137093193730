<template>
    <div id="learndativity_svg">
        <svg id="svg_learndativity" class="learndativity_svg" viewBox="0 0 1536.83 350">

            <text class="learndativity_svg__titulo" transform="translate(.5 134.9)">
                <tspan class="learn" x="10" y="0">LEARN</tspan>
                <tspan class="data" x="630" y="0">D</tspan>
                <tspan class="data ativity" x="750" y="0">AT</tspan>
                <tspan class="ativity" x="985" y="0">IVITY</tspan>
            </text>
            <g class="learndativity_svg__izquierda">
                <polyline class="learndativity_svg__barra" points="47.73 187.38 63.83 203.49 542.62 203.49 558.43 187.68" />
                <g>
                    <polyline class="learndativity_svg__barra"
                        points="216.55 319.43 216.55 274.08 239.28 256.35 282.95 256.35 300.02 244.04 300.02 203.49" />
                    <polygon class="learndativity_svg__flecha" points="226.47 334.38 216.5 317.64 206.53 334.38 226.47 334.38" />
                </g>
            </g>
            <g class="learndativity_svg__centro">
                <polyline class="learndativity_svg__barra" points="691.3 163.22 707.41 179.33 938.9 179.33 954.71 163.52" />
                <g>
                    <polyline class="learndativity_svg__barra" points="787.75 179.33 787.75 251.93 766.92 272.76 766.92 319.43" />
                    <polygon class="learndativity_svg__flecha" points="757 334.38 766.97 317.64 776.94 334.38 757 334.38" />
                </g>
            </g>
            <g class="learndativity_svg__derecha">
                <polyline class="learndativity_svg__barra" points="822.77 205.33 838.88 221.44 1430.42 221.44 1446.22 205.63" />
                <g>
                    <polyline class="learndativity_svg__barra"
                        points="1134.5 221.44 1134.5 260.67 1152.17 278.34 1279.58 278.34 1304.56 303.32 1304.56 319.43" />
                    <polygon class="learndativity_svg__flecha" points="1294.64 334.38 1304.61 317.64 1314.59 334.38 1294.64 334.38" />
                </g>
            </g>

        </svg>
        <div class="contenedor">
            <div id="learning" class="contenedor__interno">
                <p class="contenedor__titulo">LEARNING</p>
                <p class="contenedor__texto" v-html="textos[0]"></p>
            </div>
            <div id="data" class="contenedor__interno">
                <p class="contenedor__titulo">DATA</p>
                <p class="contenedor__texto" v-html="textos[1]"></p>
            </div>
            <div id="creativity" class="contenedor__interno">
                <p class="contenedor__titulo">CREATIVITY</p>
                <p class="contenedor__texto" v-html="textos[2]"></p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Learndativity SVG PC",
    props: {
        textos: Object,
    },
    setup(props) {
        const textos = props.textos;
        return { textos };
    },
}
</script>