<template>
  <section v-if="error">
    <PaginaError />
  </section>
  <section v-if="loading">
    <PaginaCarga />
  </section>
  <section v-else id="home">
    <Menu />
    <BloqueUno :bloque_uno="jsonHome.bloque_uno" />
    <BloqueDos :bloque_dos="jsonHome.bloque_dos" />
    <!-- <BannerPromocional/>-->
    <BloqueTres :bloque_tres="jsonHome.bloque_tres" />
    <BloqueCuatro :bloque_cuatro="jsonHome.bloque_cuatro" />
    <BloqueCinco :bloque_cinco="jsonHome.bloque_cinco" />
    <Footer />
  </section>
</template>

<script>
import { useQuery } from "@vue/apollo-composable";
import Paginas from "../graphql/Paginas.gql";
import { computed } from "vue";

import Menu from "@/components/menu/MenuPrincipal.vue";
import Footer from "@/components/footer/FooterPrincipal.vue";

import BloqueUno from "@/components/bloqueUno/BloqueUnoPrincipal.vue";
import BloqueDos from "@/components/bloqueDos/BloqueDosPrincipal.vue";
//import BannerPromocional from '@/components/bannerPromocional/BannerPromocionalPrincipal.vue'
import BloqueTres from "@/components/bloqueTres/BloqueTresPrincipal.vue";
import BloqueCuatro from "@/components/bloqueCuatro/BloqueCuatroPrincipal.vue";
import BloqueCinco from "@/components/bloqueCinco/BloqueCincoPrincipal.vue";
import PaginaCarga from "@/components/paginaCarga/paginaCarga.vue";
import PaginaError from "@/components/paginaError/paginaError.vue";

export default {
  name: "Home",
  components: {
    BloqueUno,
    BloqueDos,
    // BannerPromocional,
    BloqueTres,
    BloqueCuatro,
    BloqueCinco,
    Menu,
    Footer,
    PaginaCarga,
    PaginaError,
  },
  mounted() {
    this.scrollIntoFrom();
  },
  methods: {
    scrollIntoFrom() {
      window.addEventListener("load", () => {
        if (
          document.referrer != "/" &&
          window.location.href.includes("contacta")
        ) {
          setTimeout(() => {
            document
              .getElementById("contacta")
              .scrollIntoView({ behavior: "smooth" });
          }, 1000);
        }
      });
    },
  },
  setup() {
    const { result, loading, error } = useQuery(Paginas.getHome);
    const jsonHome = computed(() => result.value?.getHome.bloques ?? []);

    return { jsonHome, loading, error };
  },
};
</script>
