
<template>
  <div id="divVideo" class="contenedorVideoBloqueUno">
    <video
      id="my-video"
      ref="videoBloqueUno"
      class="video-js vjs-default-skin"
      data-setup='{ "controls": true, "preload": "auto", "muted":true}'
      controlslist="nodownload"
      crossorigin="anonymous"
    >
      <source id="rutaVideo" :src="video" type="application/x-mpegURL" />
    </video>

    <div id="audio-control" class="animacionSonido" @click="this.controlMute()">
      <picture>
        <img
          id="imgSonido"
          class="imgSonido"
          src="https://d2oolfm98c0n60.cloudfront.net/webs-corporativas/pharma/imagenes/img-bloque-uno/off-dark.svg"
          alt=""
        />
      </picture>
      <p id="activame-texto">Actívame</p>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import videojs from "video.js";
import "@/js/videojs-resolution-switcher.js";

export default {
  name: "VideoBloqueUno",
  el: "#divVideo",
  props: {
    url_video: Object,
  },
  setup(props) {
    const datos = props.url_video;
    const video = window.matchMedia("(max-width: 768px)").matches
      ? ref(datos.movil)
      : ref(datos.pc);
    return { video };
  },
  mounted() {
    videojs(this.$refs.videoBloqueUno);
  },
  methods: {
    controlMute() {
      if (document.getElementsByClassName("animacionSonido").length) {
        videojs(this.$refs.videoBloqueUno).muted(false);
        document.getElementById("imgSonido").src =
          "https://d2oolfm98c0n60.cloudfront.net/webs-corporativas/pharma/imagenes/img-bloque-uno/on-dark.svg";
          document.getElementById("activame-texto").style.display = "none";
      } else {
        videojs(this.$refs.videoBloqueUno).muted(true);
        document.getElementById("imgSonido").src =
          "https://d2oolfm98c0n60.cloudfront.net/webs-corporativas/pharma/imagenes/img-bloque-uno/off-dark.svg";
      }
      document
        .getElementById("audio-control")
        .classList.toggle("animacionSonido");
    },
  },
  beforeDestroy() {
    var oldPlayer = document.getElementById("my-video");
    videojs(oldPlayer).dispose();
  },
};
</script>
