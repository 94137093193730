<template>
  <section id="bloqueDos">
    <div class="bloqueDos">

      <div class="contenedor">
        <p class="contenedor__texto">{{ datos.titulo.es }}</p>
      </div>

      <div id="tambor" class="tambor">
        <div :id="index" class="tambor__texto" v-for="(frases, index) in contenido" :key="index">
          <p>
            Porque <strong>{{ frases.frases.es[Math.floor(Math.random() * 4)] }}</strong>
          </p>
        </div>
      </div>

      <div class="contenedor_boton">
        <p class="texto">¡Pienso como vosotros!</p>
        <button @click="formulario()" class="boton">Hablemos</button>
      </div>

      <scroll-indicator />

      <div class="control_pag">
        <p class="control_pag__texto" @click="cambiarBloque('#bloqueUno', 0)">
          02
        </p>
        <div class="control_pag__progress">
          <span class="bola"><span></span></span>
          <span class="barras" @click="cambiarTambor(1)"></span>
          <span class="barras" @click="cambiarTambor(2)"></span>
          <span class="barras" @click="cambiarTambor(3)"></span>
        </div>
        <p class="control_pag__texto" @click="cambiarBloque('#bloqueTres', 4)">
          03
        </p>
      </div>

    </div>
  </section>
</template>

<script>
import ScrollIndicator from "../scrollIndicator/ScrollIndicator.vue";
import { ref } from "vue";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default {
  name: "bloqueDosPrincipal",
  components: {
    ScrollIndicator,
  },
  props: {
    bloque_dos: Object,
  },
  setup(props) {
    const datos = props.bloque_dos;
    //const tambor = ref([...datos.contenido[posicion.value].frases.es]);
    const contenido = ref([...datos.contenido]);
    const tl = ref();
    const st = ref();

    return { datos, contenido, st, tl };
  },
  mounted() {
    this.tl = gsap.timeline({});
    // ScrollTrigger.config({ignoreMobileResize: true,syncInterval: 999999999});
    // ScrollTrigger.normalizeScroll(true);
    this.st = ScrollTrigger.create({
      trigger: "#bloqueDos",
      animation: this.tl,
      pin: true,
      markers: false,
      start: "-80px top",
      end: "+=200%",
      scrub: true,
    });
    this.cargarTambor();
  },
  methods: {
    cargarTambor() {
      var bola = document.querySelectorAll('#bloqueDos .bola');
      var tambor = document.querySelectorAll('#bloqueDos .tambor__texto');

      this.tl.to(bola, { height: 0 }).addLabel("barra_0");

      this.tl.to(bola, { duration: 1, height: 35, }).addLabel("barra_1");
      this.tl.to(tambor, { duration: 1, top: '-100%' }, '-=1').addLabel("barra_1");

      this.tl.to(bola, { duration: 1, height: 70, }).addLabel("barra_2");
      this.tl.to(tambor, { duration: 1, top: '-200%' }, '-=1').addLabel("barra_2");

      this.tl.to(bola, { duration: 1, height: 105, }).addLabel("barra_3");
      this.tl.to(tambor, { duration: 1, top: '-300%' }, '-=1').addLabel("barra_3");

      this.tl.to(bola, { duration: 1, height: 135 }).addLabel("barra_4");
    },
    cambiarTambor(index) {
      gsap.to(window, {scrollTo: this.tl.scrollTrigger.labelToScroll(`barra_${index}`)});
    },
    cambiarBloque(bloque, index) {
      gsap.to(window, {scrollTo: this.tl.scrollTrigger.labelToScroll(`barra_${index}`)});
      gsap.to(window, { duration: 1, scrollTo: bloque, delay: 1 });
    },
    formulario() {
      document.getElementById('contacta').scrollIntoView();
    }
  },
};
</script>