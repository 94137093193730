<template>
  <section id="bloqueUno" class="bloqueUno">
    <VideoScroll :url_video="video" />

    <section class="bloqueUno__contendor">
      <h1 class="bloqueUno__titulo">{{ datos.titulo.es }}</h1>
      <p class="bloqueUno__parrafo" v-html="contenido"></p>

      <img class="bloqueUno__flecha"
        src="https://d2oolfm98c0n60.cloudfront.net/webs-corporativas/pharma/imagenes/img-bloque-uno/flecha-pc.svg"
        alt="flecha derecha bloque uno" />

      <ScrollIndicator />
    </section>

    <div class="control_pag">
      <p class="control_pag__texto" @click="cambiarBloque('#bloqueUno', 0)">01</p>
      <div class="control_pag__progress">
        <span class="bola"><span></span></span>
        <span class="barras" @click="mostrarVideo()"></span>
      </div>
      <p class="control_pag__texto" @click="cambiarBloque('#bloqueDos', 1)">02</p>
    </div>
  </section>
</template>

<script>
import ScrollIndicator from "../scrollIndicator/ScrollIndicator.vue"
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { ref } from "@vue/reactivity";
import VideoScroll from "@/components/bloqueUno/componentsInternos/VideoScroll.vue";

const $ = require("jquery");

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "BloqueUnoPrincipal",
  components: {
    VideoScroll,
    ScrollIndicator,
  },
  mounted() {
    //Estos metodos se ejecutan directamente al montarlos
    this.tlBloqueUno = gsap.timeline({});
    // ScrollTrigger.config({ ignoreMobileResize: true, syncInterval: 999999999 });
    // ScrollTrigger.normalizeScroll(true);
    this.stBloqueUno = ScrollTrigger.create({
      trigger: "#bloqueUno",
      animation: this.tlBloqueUno,
      pin: true,
      markers: false,
      start: "top top",
      end: "+=240%",
      scrub: true,
    });
    this.visualizarVideo();
  },
  props: {
    bloque_uno: Object,
  },
  setup(props) {
    const datos = props.bloque_uno;
    const video = ref(datos.url_video);
    const tlBloqueUno = ref();
    const stBloqueUno = ref();
    var contenido = datos.descripcion.texto.es;
    Array.from(datos.descripcion.destacadas.es).forEach((element) => {
      contenido = contenido.replace(element, `<strong><span class='neon'>${element}</span></strong>`);
    });

    return { datos, contenido, video, tlBloqueUno, stBloqueUno };
  },
  methods: {
    visualizarVideo() {
      gsap.matchMediaRefresh;
      let mm = gsap.matchMedia();

      mm.add("(min-width: 767px)", () => {

        this.tlBloqueUno.to("#bloqueUno .bola", { duration: 1, height: 0 }).addLabel("bola_0");

        this.tlBloqueUno.to("#divVideo", {
          duration: 1, left: "0%",
          onComplete: () => {
            if (
              !navigator.userAgent.match(/webOS/i) &&
              !navigator.userAgent.match(/iPhone/i) &&
              !navigator.userAgent.match(/iPad/i) &&
              !navigator.userAgent.match(/iPod/i)
            ) {
              document.getElementById('my-video').player.play();
            }
          },
          onUpdate: () => {
            if (document.getElementById('my-video').classList.contains('vjs-playing')) {
              document.getElementById('my-video').player.pause();
            }
          }
        }).addLabel("mostrarVideo");

        this.tlBloqueUno.to("#bloqueUno .bola", { duration: 1, height: 51 }, "<").addLabel("mostrarVideo");
        this.tlBloqueUno.to("#bloqueUno .bola", {
          duration: 1, height: 100,
          onComplete: () => { document.getElementById('my-video').player.pause(); },
          onUpdate: () => {
            if (document.getElementById('my-video').classList.contains('vjs-paused')) {
              if (
                !navigator.userAgent.match(/webOS/i) &&
                !navigator.userAgent.match(/iPhone/i) &&
                !navigator.userAgent.match(/iPad/i) &&
                !navigator.userAgent.match(/iPod/i)
              ) {
                document.getElementById('my-video').player.play();
              }
            }
          }
        }).addLabel("bola_1");

      })
      mm.add("(max-width: 768px)", () => {
        this.tlBloqueUno.to("#divVideo", {
          duration: 1, top: 80,
          onComplete: () => {
            if (
              !navigator.userAgent.match(/webOS/i) &&
              !navigator.userAgent.match(/iPhone/i) &&
              !navigator.userAgent.match(/iPad/i) &&
              !navigator.userAgent.match(/iPod/i)
            ) {
              document.getElementById('my-video').player.play();
            }
          },
          onUpdate: () => {
            if (document.getElementById('my-video').classList.contains('vjs-playing')) {
              document.getElementById('my-video').player.pause();
            }
          }
        });
        this.tlBloqueUno.to("#bloqueUno .bola", {
          duration: 1, height: 100,
          onComplete: () => { document.getElementById('my-video').player.pause(); },
          onUpdate: () => {
            if (document.getElementById('my-video').classList.contains('vjs-paused')) {
              if (
                !navigator.userAgent.match(/webOS/i) &&
                !navigator.userAgent.match(/iPhone/i) &&
                !navigator.userAgent.match(/iPad/i) &&
                !navigator.userAgent.match(/iPod/i)
              ) {
                document.getElementById('my-video').player.play();
              }
            }
          }
        })
      })
    },
    mostrarVideo() {
      gsap.to(window, { scrollTo: this.tlBloqueUno.scrollTrigger.labelToScroll(`mostrarVideo`) });
    },
    cambiarBloque(bloque, index) {
      gsap.to(window, {
        scrollTo: this.tlBloqueUno.scrollTrigger.labelToScroll(`bola_${index}`),
      }, "<");
      gsap.to(window, { duration: 1, scrollTo: bloque, delay: 1 });
    },
    moverArriba(index) {
      gsap.to(window, {
        scrollTo: this.tlBloqueUno.scrollTrigger.labelToScroll(`bola_${index}`),
      }, "<");
      gsap.to(window, { duration: 1, scrollTo: 0, delay: 1 });
    }
  },
};
</script>