import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

if(window.location.pathname.toLowerCase() === '/learndativity'){
  if(!getCookie('isFormComplete')){
    window.location.href = "/"
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: `Home - Pharma`,
      metaTags: [
        {
          name: 'description',
          content: 'Potenciamos tu SELL OUT en la Oficina de Farmacia. Con nuestra metodología LEARNDATIVITY desarrollamos proyectos para mejorar el posicionamiento de la Industria, potenciando la imagen del Farmacéutico como referente en salud para llegar mejor al paciente.'
        },
        {
          property: 'og:description',
          content: 'Potenciamos tu SELL OUT en la Oficina de Farmacia. Con nuestra metodología LEARNDATIVITY desarrollamos proyectos para mejorar el posicionamiento de la Industria, potenciando la imagen del Farmacéutico como referente en salud para llegar mejor al paciente.'
        },
        {
          name: 'keywords',
          content: 'alebat pharma, pharma, alebat,pharma alebat'
        },
        {
          name: 'author',
          content: 'Alebat'
        }
      ]
    }
  },
  {
    path: '/politica-de-privacidad',
    name: 'PoliticaDePrivacidadView',
    component: () => import(/* webpackChunkName: "politica-de-privacidad-view" */ '../views/PoliticaDePrivacidad.vue'),
    meta: {
      title: `Politica de Privacidad - Pharma`,
      metaTags: [
        {
          name: 'description',
          content: 'Pagina principal de Politica de Privacidad. Aquí encontrarás cómo gestionamos el tratamiento de datos personales.'
        },
        {
          property: 'og:description',
          content: 'Pagina principal de Politica de Privacidad. Aquí encontrarás cómo gestionamos el tratamiento de datos personales.'
        },
        {
          name: 'author',
          content: 'Alebat'
        }
      ]
    }
  },
  {
    path: '/cookies',
    name: 'CookiesView',
    component: () => import(/* webpackChunkName: "cookies-view" */ '../views/Cookies.vue'),
    meta: {
      title: `Cookies - Pharma`,
      metaTags: [
        {
          name: 'description',
          content: 'Pagina principal de Cookies. Aquí encontrarás información sobre las Cookies.'
        },
        {
          property: 'og:description',
          content: 'Pagina principal de Cookies. Aquí encontrarás información sobre las Cookies.'
        },
        {
          name: 'author',
          content: 'Alebat'
        }
      ]
    }
  },
  {
    path: '/learndativity',
    name: 'LearndativityView',
    component: () => import(/* webpackChunkName: "leardativity-view" */ '../views/Learndativity.vue'),
    meta: {
      title: `Learndativity - Pharma`,
      metaTags: [
        {
          name: 'description',
          content: 'Pagina principal de Cookies. Aquí encontrarás información sobre las Cookies.'
        },
        {
          property: 'og:description',
          content: 'Pagina principal de Cookies. Aquí encontrarás información sobre las Cookies.'
        },
        {
          name: 'author',
          content: 'Alebat'
        }
      ]
    },
    children: [
      { path: '/learndativity', name: 'LearndativityView', component: () => import("@/views/Learndativity.vue")},
      { path: '/learndativity/:params', name: 'LearndativityView', component: () => import("@/views/Learndativity.vue") },
  ]
  },
  {
    path: '/servicios',
    name: 'servicios',
    component: () => import(/* webpackChunkName: "servicios-view" */ '../views/servicios.vue'),
    meta: {
      title: `Servicios - Pharma`,
      metaTags: [
        {
          name: 'description',
          content: 'Encontraras los distintos servicios que ofrecemos.'
        },
        {
          property: 'og:description',
          content: 'Encontraras los distintos servicios que ofrecemos.'
        },
        {
          name: 'author',
          content: 'Alebat'
        }
      ]
    }
  },
  {
    path: '/gracias',
    name: 'gracias',
    component: () => import(/* webpackChunkName: "servicios-view" */ '../views/Gracias.vue'),
    meta: {
      title: `Gracias - Pharma`,
      metaTags: [
        {
          name: 'description',
          content: 'Página de gracias de Alebat Pharma.'
        },
        {
          property: 'og:description',
          content: 'Página de gracias de Alebat Pharma.'
        },
        {
          name: 'author',
          content: 'Alebat'
        }
      ]
    }
  },
  {
    path: '/PatientExperienceHub',
    name: 'experienceHub',
    component: () => import(/* webpackChunkName: "experience-hub" */ '../views/ExperienceHubPrincipal.vue'),
    meta: {
      title: `Patient Experience - Pharma`,
      metaTags: [
        {
          name: 'description',
          content: 'La mejora de la Experiencia de Paciente es clave para el futuro de nuestra salud. Por eso te invitamos a acompañarnos el próximo 17 de Noviembre de 18:00 a 20:30h en la Tasca la Farmacia'
        },
        {
          property: 'og:description',
          content: 'La mejora de la Experiencia de Paciente es clave para el futuro de nuestra salud. Por eso te invitamos a acompañarnos el próximo 17 de Noviembre de 18:00 a 20:30h en la Tasca la Farmacia'
        },
        {
          name: 'author',
          content: 'Alebat'
        }
      ]
    }
  },
  { 
    path: '/:pathMatch(.*)*', 
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  // Borramos todas las etiquetas meta que son de la antigua pagina
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  //Comprobamos que tiene puesta las metaTags
  if (!to.meta.metaTags) return next();
  
  to.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    // Usamos esto para rastrear qué metaetiquetas creamos para no interferir con otras.
    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  }).forEach(tag => document.head.appendChild(tag));

  next();
})

export default router
