<template>
  <footer id="footer" class="footer">
    <MensajeCookies /> 
    <section class="footer__section1">
      <div>
        <picture>
          <img
            class="logo"
            src="https://d2oolfm98c0n60.cloudfront.net/webs-corporativas/pharma/imagenes/Alebat-pharma-moviles-53.webp"
            alt=""
          />
        </picture>
        <p class="texto_ae">Un Proyecto Alebat, la marca que cuida la salud de las marcas que cuidan de la nuestra</p>
      </div>
      <div class="accesos_directos">
        <!-- <router-link to="/blog">
          <p class="texto"><i>Blog</i></p>
        </router-link> -->
        <a href="/servicios" target="_blank">
          <p class="texto"><i>Servicios</i></p>
        </a>
        <a href="/#contacta">
          <p class="texto"><i>Contacto</i></p>
        </a>
      </div>
    </section>

    <section class="footer__section2">
      <p class="texto_ae">
        Powered by <a href="https://www.alebateducation.com/" target="_BLANK">Alebat</a> Healthcare Education
      </p>
      <hr />
      <div class="politicas_rrss">
        <div class="politicas">
          <a href="/politica-de-privacidad" target="_blank">
            <p class="texto">POLÍTICA PRIVACIDAD</p>
          </a>
          <a href="/cookies" target="_blank">
            <p class="texto">COOKIES</p>
          </a>
        </div>
        <div class="rrss">
          <div class="div_rrss">
            <a href="https://es.linkedin.com/company/alebat-education" target="_BLANK">
              <picture>
                <img
                  class="img_rrss"
                  src="https://d2oolfm98c0n60.cloudfront.net/webs-corporativas/pharma/imagenes/img-footer/linkedin-icon.svg"
                  alt="Icono de linkedin de alebat education"
                />
              </picture>
            </a>
          </div>
          <!-- <div class="div_rrss">
            <a href="">
              <picture>
                <img
                  class="img_rrss"
                  src="https://d2oolfm98c0n60.cloudfront.net/webs-corporativas/pharma/imagenes/img-footer/instagram.png"
                  alt=""
                />
              </picture>
            </a>
          </div> -->
          <!-- <div class="div_rrss">
            <a href="">
              <picture>
                <img
                  class="img_rrss"
                  src="https://d2oolfm98c0n60.cloudfront.net/webs-corporativas/pharma/imagenes/img-footer/twitter.png"
                  alt=""
                />
              </picture>
            </a>
          </div> -->
        </div>
      </div>
    </section>
  </footer>
</template>

<script>

import MensajeCookies from "@/components/mensajeCookies/mensajeCookies.vue";
export default {
  name: "FooterPrincipal",
  components: {
    MensajeCookies,
  },

  mounted() {
    this.modalCookie();
  },
  methods: {
    modalCookie() {
      const $ = require("jquery");

      $(document).ready(function () {
        let OBLIGATORIAS = 1;
        let ESTADISTICA = 2;
        var cookieConsent = parseInt(getCookie("cookie-consent"));

        if (!cookieConsent) {
          $(".cookies-background-block").fadeIn(100);
        } else {
          switch (cookieConsent) {
            case OBLIGATORIAS:
              break;
            case ESTADISTICA:
              var cookieAnalytics1 = getCookie("_ga");
              var cookieAnalytics2 = getCookie("_gid");
              if (!cookieAnalytics1 || !cookieAnalytics2) {
                runAnalytics();
              }
              break;
            default:
              break;
          }
          closeCookies();
        }
        $("body").on("click", "#conf-cookies", function () {
          $(".cookie-banner-small").fadeOut(250, function () {
            // $("#cookies-panel").css({'height': '650'});
            if ($(window).width() > 750 && $(window).width() < 1024) {
              desplegarTablet();
            } else if ($(window).width() < 750) {
              desplegarMovil();
            } else {
              desplegarOrdenador();
            }
          });
        });
        $("body").on("click", "#back-cookie", function () {
          $(".cookie-banner-big").fadeOut(250, function () {
            // $("#cookies-panel").css({'height': '650'});
            if ($(window).width() > 750 && $(window).width() < 1024) {
              retirarTablet();
            } else if ($(window).width() < 750) {
              retirarMovil();
            } else {
              retirarOrdenador();
            }
          });
        });
        $("body").on("click", "#allow-all , #allow-cookies", function () {
          runAnalytics();
          setCookie("cookie-consent", 2, 1);
          closeCookies();
        });
        $("body").on("click", "#denied-all , #denied-cookies", function () {
          setCookie("cookie-consent", 1, 1);
          closeCookies();
        });
        $("body").on("click", "#save-cookies", function () {
          if ($("#estadisticas:checked")) {
            runAnalytics();
            setCookie("cookie-consent", 2, 1);
            closeCookies();
          } else {
            setCookie("cookie-consent", 1, 1);
            closeCookies();
          }
        });
        $("body").on("click", ".round", function () {
          if (
            $(this).find("input").is(":checked") &&
            !$(this).find("input").is(":disabled")
          ) {
            $(this).find("input").prop("checked", false);
          } else {
            $(this).find("input").prop("checked", true);
          }
        });
        function closeCookies() {
          if ($(".cookies-background-block").is(":visible")) {
            $("#cookies-panel, .cookies-background-block").fadeOut(100);
          } else {
            var intervalo = setInterval(function () {
              if ($(".cookies-background-block").is(":visible")) {
                clearInterval(intervalo);
                $("#cookies-panel, .cookies-background-block").fadeOut(100);
              }
            }, 100);
          }
        }
        function getCookie(cnombre) {
          var nombre = cnombre + "=";
          var ca = document.cookie.split(";");
          for (var i = 0; i < ca.length; i++) {
            var c = ca[i].trim();
            if (c.indexOf(nombre) == 0)
              return c.substring(nombre.length, c.length);
          }
          return false;
        }
        function setCookie(nombre, val, dias) {
          var d = new Date();
          d.setTime(d.getTime() + dias * 24 * 60 * 60 * 1000);
          var expires = "expires=" + d.toGMTString();
          document.cookie = nombre + "=" + val + "; " + expires + ";path=/";
        }
        function runAnalytics() {
          var Url = window.location;

         /* if (
            Url.host == "alebateducation.com" ||
            Url.host == "www.alebateducation.com"
          ) {
            var codigoAnalytics =
              "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','//www.google-analytics.com/analytics.js','ga');ga('create', 'UA-70735004-1', 'auto');ga('require', 'displayfeatures');ga('send', 'pageview');ga('set', 'anonymizeIp', true);";

            eval(codigoAnalytics);
          }*/
        }
        function desplegarTablet() {
          $(".cookie-banner-big").fadeIn(250);
        }
        function desplegarOrdenador() {
          $(".cookie-banner-big").fadeIn(250);
        }
        function desplegarMovil() {
          $(".cookie-banner-big").fadeIn(250);
        }
        function retirarTablet() {
          $(".cookie-banner-small").fadeIn(250);
        }
        function retirarOrdenador() {
          $(".cookie-banner-small").fadeIn(250);
        }
        function retirarMovil() {
          $(".cookie-banner-small").fadeIn(250);
        }
      });
    },
  },
};
</script>